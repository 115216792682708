const styles = {
  root: {
    paddingY: { xs: 2, lg: 9 },
    backgroundColor: 'background.default',
  },
  mainContainer: {
    position: 'relative',
    '& .pointer': {
      cursor: 'pointer',
    },
  },
  mainTitle: {
    '&.MuiTypography-h1': {
      color: 'text.primaryLight',
    },
  },
  iconPlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainerBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&.alignItemsStart': {
      alignItems: 'self-start',
      '& > .MuiGrid-root:last-of-type': {
        marginTop: { lg: 2 },
      },
    },
  },

  isMobile: {
    display: { xs: 'block', md: 'none' },
  },
  isDesktop: {
    display: { xs: 'none', md: 'flex' },
  },
}

export default styles
