import React, { memo, useState } from 'react'
import dynamic from 'next/dynamic'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import Container from '@mui/material/Container'
import Timeline from 'components/common/Timeline'
import PlayIcon from 'components/icons/playIcon.svg'
import Image from 'components/shared/ImageLegacy'
import clsx from 'clsx'
import Divider from '@mui/material/Divider'
import sx from './styles'

const VideoModalDynamic = dynamic(() => import('components/VTA/VideoModal'))

const TimelineAndVideo = ({
  data,
  tabs,
  children,
  sxImg = {},
  imageEasy,
  variant,
  imageEasyMobile,
  objectFitVariant = 'cover',
  showDivider = true,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const showVideo = () => setIsVideoVisible((prev) => !prev)

  const {
    image, mobileImage, videoUrl, title, timeline, disclaimer,
  } = data

  const imageSRC = (isMobile ? mobileImage?.url : image?.url) || ''

  return (
    <Box>
      <Container
        maxWidth="lg"
        sx={sx.root}
      >
        {
          (variant)
            ? (
              <Grid
                container
                sx={isMobile ? sx.containerVariantMobile : sx.containerVariant}
              >
                {
                  (imageEasy || imageEasyMobile) && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                    >
                      <Image
                        src={isMobile ? imageEasyMobile : imageEasy}
                        width="100%"
                        height={isMobile ? 300 : 400}
                        layout="fill"
                        // blurDataURL={isMobile ? imageEasyMobile : imageEasy}
                        placeholder="blur"
                        alt={image?.alt || image?.title || 'Vende tu auto con ClikAuto'}
                        priority
                      />
                    </Grid>
                  )
                }
                <Grid
                  item
                  xs={12}
                  md={imageEasy ? 6 : 12}
                  lg={imageEasy ? 6 : 12}
                  sx={sx.timelineContainerVariant}
                  container
                  alignItems="left"
                >
                  <Grid
                    item
                    xs={12}
                    mt={isMobile ? 2 : 0}
                  >
                    <Box>
                      <Typography
                        variant="h3"
                        sx={sx.timelIneTitle}
                        className="TimelineAndVideo-title"
                      >
                        {title}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Timeline
                      data={timeline}
                      tabs={tabs}
                      showLinks
                      variant="vertical"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <Box sx={sx.childContainer}>
                      {children}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )
            : (
              <Grid
                container
                sx={isMobile ? sx.containerHomeMobile : sx.container}
                className="Timeline-Container-root"
              >
                {imageSRC && (
                  <Grid
                    item
                    xs={12}
                    md={2}
                    lg={2}
                  >
                    <VideoModalDynamic
                      src={videoUrl}
                      open={isVideoVisible}
                      onClose={() => setIsVideoVisible(false)}
                      autoplay
                    />
                    <Box
                      sx={sx.imageContainer}
                      className={clsx({
                        withVideo: videoUrl,
                      })}
                      onClick={showVideo}
                    >
                      {videoUrl && (
                        <Box sx={sx.iconPlay}>
                          <PlayIcon />
                        </Box>
                      )}
                      <Image
                        src={imageSRC}
                        width="100%"
                        height={isMobile ? 170 : 422}
                        layout="fill"
                        objectFit={objectFitVariant}
                        blurDataURL={imageSRC}
                        placeholder="blur"
                        alt={image?.alt || image?.title}
                        priority
                        {...sxImg}
                      />
                    </Box>
                  </Grid>
                )}

                {
                  imageEasy && (
                    <Grid
                      item
                      xs={12}
                      md={5}
                    >
                      <Image
                        src={imageEasy}
                        width="100%"
                        height={400}
                        layout="fill"
                        blurDataURL={imageEasy}
                        placeholder="blur"
                        alt={image?.alt || image?.title}
                        priority
                        {...sxImg}
                      />
                    </Grid>
                  )
                }

                <Grid
                  item
                  xs={12}
                  md={(imageSRC || imageEasy) ? 10 : 12}
                  lg={(imageSRC || imageEasy) ? 10 : 12}
                  sx={sx.timelineContainer}
                  container
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Box>
                      <Typography
                        variant="h3"
                        sx={sx.timelIneTitle}
                      >
                        {title}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Timeline
                      data={timeline}
                      tabs={tabs}
                      showLinks
                    />
                  </Grid>

                  {disclaimer && (
                    <Grid
                      item
                      xs={12}
                    >
                      <Box sx={sx.disclaimerContainer}>
                        <Typography variant="body2">
                          {disclaimer}
                        </Typography>
                      </Box>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                  >
                    <Box
                      sx={sx.childContainer}
                    >
                      {children}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )
        }
        {
          (showDivider && variant && !isMobile) && (
            <Divider />
          )
        }
      </Container>
    </Box>
  )
}

export default memo(TimelineAndVideo)
