import React, { createContext, useState } from 'react'

// Crear el contexto
export const ToggleCollapseContext = createContext()

// Proveedor del contexto
export const ToggleCollapseProvider = ({ children }) => {
  const [openCollaseItem, setOpenCollaseItem] = useState(null)

  const toggleOpenItem = (item) => {
    setOpenCollaseItem((prev) => (prev === item ? null : item)) // Alternar entre abrir y cerrar
  }

  return (
    <ToggleCollapseContext.Provider value={{ openCollaseItem, toggleOpenItem }}>
      {children}
    </ToggleCollapseContext.Provider>
  )
}
