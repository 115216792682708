import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useIntl } from 'react-intl'
import ScrollLock from 'react-scrolllock'
import { useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { useModal } from './hooks/useModal'
import sx from './ModalStyles'

const Modal = () => {
  const { modalState, handleCloseModal } = useModal()
  const { formatMessage: f } = useIntl()
  const {
    show,
    title,
    body,
    actionButtons,
    isDismissible,
    configProps = {},
    onClose = null,
    onCloseCallback = () => null,
  } = modalState
  const {
    maxWidth,
    className,
    sxProps,
    showDividers,
    scroll,
    paperProps,
    titleAlign = 'left',
    showClose,
    isDividerHeader = false,
    isDividerBottom = false,
    isFormattedTitle,
    sxProp = {},
    scrollLock = false,
    fullScreen = false,
    isMiddleScreen = false,
    variantTitle = 'h4',
    ...otherProps
  } = configProps
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const paperWidth = !maxWidth ? { maxWidth: isMobile ? '95%' : 720 } : {}
  const borderBotton = isDividerHeader ? sx.borderBotton : {}
  const borderTop = isDividerBottom ? sx.borderTop : {}

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick' && isDismissible) return
    handleCloseModal()
  }

  const handleCloseButton = () => {
    handleCloseModal()
    if (onCloseCallback) {
      onCloseCallback()
    }
  }

  return (
    <Dialog
      open={show}
      fullScreen={fullScreen}
      aria-labelledby="app-modal"
      onClose={onClose || handleClose}
      maxWidth={maxWidth}
      disablebackdropclick={isDismissible || 'false'}
      disableEscapeKeyDown={isDismissible || false}
      scroll={scroll}
      sx={{ ...sx.dialog, ...sxProps }}
      PaperProps={{
        sx: { borderRadius: 0.5, ...paperWidth },
      }}
      className={clsx({
        isMiddleScreen,
      })}
      {...otherProps}
    >
      {title && (

        <Box
          sx={{ ...sx.contentTitle, ...borderBotton }}
          className="ModalHeader-root"
        >
          <Typography
            variant={variantTitle}
            sx={{ ...sx.title, ...sxProp.title }}
            align={titleAlign}
            component="h4"
            className={clsx({
              isMiddleScreen,
            })}
          >
            {isFormattedTitle ? f({ id: title }) : title}
          </Typography>
          {showClose && (
            <IconButton
              sx={sx.closeButton}
              onClick={handleCloseButton}
              className={clsx({
                isMiddleScreen,
              })}
            >
              <CloseIcon sx={sx.closeIcon} />
            </IconButton>
          )}
        </Box>
      )}
      <ScrollLock isActive={scrollLock}>
        <DialogContent
          sx={sx.contentBody}
          dividers={showDividers}
        >
          {body}
        </DialogContent>
      </ScrollLock>

      {actionButtons && <DialogActions sx={borderTop}>{actionButtons}</DialogActions>}
    </Dialog>
  )
}

export { Modal }
