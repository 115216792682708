/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { validAdvancedFilterKeys } from 'vars'

const initialState = {
  filters: null,
  findACar: null,
  isSeeVehiclesBtnDisabled: false,
  filtersVariant: null,
  totalVehicles: 0,
  resetFilter: 0,
  modelsByMake: {},
  states: [],
  citiesByState: {},
  loading: false,
  page: 1,
  userViews: typeof window !== 'undefined'
    ? window.sessionStorage.getItem('userViews')
    : null,
  showCreditPersons: typeof window !== 'undefined'
    ? Boolean(window.sessionStorage.getItem('showCreditPersons') === 'true')
    : Math.floor(Math.random() * 2) === 0,
}

const searchSlice = createSlice({
  name: 'search',
  initialState,

  reducers: {
    setFilter: (state, action) => {
      const { key, value } = action.payload
      state.filters[key] = value
    },

    setFilters: (state, action) => {
      const filters = action.payload
      if (state.filters) {
        Object.assign(state.filters, filters)
      } else {
        state.filters = filters
      }
    },

    setFindACarFilters: (state, action) => {
      const filters = action.payload
      if (state.findACar) {
        Object.assign(state.findACar, filters)
      } else {
        state.findACar = filters
      }
    },

    resetFindACarFilters: (state) => {
      state.findACar = {}
    },

    setVariantFilters: (state, action) => {
      const filters = action.payload
      if (state.filtersVariant) {
        Object.assign(state.filtersVariant, filters)
      } else {
        state.filtersVariant = filters
      }
    },

    setOverridedFilters: (state, action) => {
      const filters = action.payload
      state.filters = filters
    },

    resetFilters: (state) => {
      state.filters = {}
      state.resetFilter += 1
    },

    resetFiltersState: (state) => {
      state.filters.state = ''
    },
    resetFiltersWithOutBody: (state) => {
      state.filters = {
        body: state.filters?.body,
        owner_type: state.filters?.owner_type,
        holding_type: state.filters?.holding_type,
      }
      state.resetFilter += 1
    },

    resetAdvanceFilters: (state) => {
      // state.filters = Object.entries(state.filters)
      //   .filter(([key]) => validAdvancedFilterKeys?.includes(key))
      state.filters = {
        ...state.filters,
        maker: '',
        model: '',
        transmission: '',
        year: '',
      }
      state.resetFilter += 1
    },

    resetFiltersMobile: (state) => {
      state.filters = {
        body: state.filters?.body,
        owner_type: state.filters?.owner_type,
        holding_type: state.filters?.holding_type,
        min: state.filters?.min,
        max: state.filters?.max,
        sort: state.filters?.sort,
      }
      state.resetFilter += 1
    },

    resetYearFilter: (state) => {
      delete state.filters.year
    },

    resetVariantFilters: (state) => {
      state.filtersVariant = {}
    },

    resetFiltersSort: (state) => {
      state.filters.sort = ''
    },

    setModelsByMake: (state, action) => {
      const { maker, models } = action.payload
      state.modelsByMake[maker] = models
    },

    setCitiesByState: (state, action) => {
      const citiesByState = action.payload
      state.citiesByState = citiesByState
    },

    setStates: (state, action) => {
      const states = action.payload
      state.states = states
    },

    setPage: (state, action) => {
      const page = action.payload
      state.page = page
    },
    setValue: (state, action) => {
      const { key, value, persist } = action.payload
      if (persist) {
        window.sessionStorage.setItem(
          key,
          typeof value === 'object' ? JSON.stringify(value) : String(value),
        )
      }
      state[key] = value
    },
  },
})

export default searchSlice.reducer
export const {
  setFilter,
  setFilters,
  setVariantFilters,
  resetFilters,
  resetVariantFilters,
  resetFiltersState,
  resetFiltersSort,
  setModelsByMake,
  setCitiesByState,
  setStates,
  setPage,
  setOverridedFilters,
  setValue,
  resetFiltersWithOutBody,
  resetFiltersMobile,
  resetYearFilter,
  setFindACarFilters,
resetFindACarFilters,
  resetAdvanceFilters,
} = searchSlice.actions
