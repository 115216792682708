const styles = {
  timelineDot: {
    margin: 0,
    backgroundColor: 'third.main',
  },
  timelineItem: {
    ':before': {
      display: 'none',
    },
  },
  timelineContent: {
    padding: { xs: 0.8, md: 0 },
    paddingLeft: '8px!important',
  },
}

export default styles
