const styles = {
  icon: {
    color: 'third.main',
    fontSize: 15,
  },

  divider: {
    ml: 1,
    position: 'absolute',
    width: '100%',
    top: 9,
    color: 'red',
    // transform: 'translateX(-85%)',
  },

  enforceVertical: (enforce) => ({
    transform:
      enforce
        ? 'translateX(-100%)'
        : { xs: 'translateX(-73%)', sm: 'translateX(-85%)', md: 'none' },
  }),
}

export default styles
