const styles = {
  card: {
    width: { xs: 208, sm: 272, lg: 270 },
    borderRadius: 1,
    border: '.1px solid rgba(0,0,0,.1)',
    color: 'text.black',
    height: { xs: 344, sm: 370 },
    position: 'relative',
    '& .MuiTypography-caption': {
      fontSize: 10,
      marginTop: 0.6,
    },
    '& .MuiBox-root': {
      width: '100%',
      '& img': {
        objectFit: { xs: 'cover !important' },
      },
    },
  },
  cardRow: {
    width: '100%',
    height: 300,
    display: 'flex',
    flexDirection: 'row',
    '& .MuiBox-root': {
      width: '100%',
      height: '100%',
      '& img': {
        objectFit: 'cover!important',
        borderRadius: 1,
      },
    },
  },
  contentCard: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  tagTopText: {
    backgroundColor: 'secondary.main',
    p: 0.3,
    '&.MuiTypography-quote': { color: 'white' },
  },
  contentRow: {
    width: '60%',
    justifyContent: 'flex-start',
  },

  tags: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
  },

  contentTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    cursor: 'pointer',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  contentDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
  },
  cardLink: {
    position: 'absolute',
    bottom: -10,
    left: 0,
  },
  link: {
    margin: '0 0 10px 10px',
    fontSize: 10,
    color: 'text.black',
    textDecorationColor: (theme) => ({
      xs: `${theme.palette.text.black}!important`,
    }),
  },
}

export default styles
