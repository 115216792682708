import React, { memo, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'
import Autocomplete from '@mui/material/Autocomplete'
import { FormattedMessage, useIntl } from 'react-intl'
import { CircularProgress, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { orderedMakers } from 'utils/filters'
import { useDispatch } from 'react-redux'
import pickBy from 'lodash/pickBy'
import { setFilters } from 'redux/modules/search'
import sx from './styles'

const Form = ({
  variantRow = 'row', // row|column
  onSubmit,
  makers,
  totalVehicles,
  loading,
}) => {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const [makerInput, setMakerInput] = useState(undefined)

  const handleUpdateMaker = (value) => {
    const makersString = value?.map((m) => m.toLowerCase())?.join('_')
    setMakerInput(makersString)
    dispatch(setFilters({ maker: makersString }))
  }

  const makersCleaned = makers?.filter((m) => m?.name)
  const formattedMakers = makersCleaned
    ? orderedMakers(makersCleaned?.filter((m) => `${m?.name}`.toLowerCase() !== 'bmw-mini '))
      ?.map((m) => m?.name)
    : []

  const handleSubmit = () => {
    const params = {}
    params.maker = makerInput
    onSubmit(pickBy(params))
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={sx.mainTitle}
      >
        <Typography variant="h2">
          <FormattedMessage id="HOME_BUY_TO_CREDIT_OR_CASH" />
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        lg={12}
        spacing={isMobile ? 0 : 1}
        sx={sx.inputsContainer}
        className={clsx({
          row: variantRow === 'row',
          column: variantRow === 'column',
        })}
      >
        <Grid
          item
          xs={variantRow === 'row' ? 6 : 12}
          lg={variantRow === 'row' ? 4 : 12}
          sx={sx.item}
        >
          <Box sx={sx.titleContainer}>
            <Typography
              variant="subtitle1"
              component="p"
              sx={sx.subtitle}
            >
              <FormattedMessage
                id="HOME_CHOOSE_YOUR_MAKER"
              />
            </Typography>
          </Box>
          <Autocomplete
            multiple
            sx={sx.autocomplete}
            value={makerInput}
            options={formattedMakers || []}
            className={clsx({ touched: makerInput })}
            noOptionsText={f({ id: 'HOME_NO_OPTIONS' })}
            onChange={(event, newValue) => {
              handleUpdateMaker(newValue)
            }}
            id="combo-make"
            clearOnBlur
            renderInput={(params) => (
              <TextField
                {...params}
                sx={sx.field}
                className={clsx('Mui-style ', { touched: makerInput })}
                label={f({ id: 'MAKERS_OPTIONS' })}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          lg={variantRow === 'row' ? 4 : 12}
          sx={sx.buttonContainer}
        >
          <Button
            id="btn-home-search"
            sx={sx.button}
            fullWidth
            className={clsx({ column: variantRow === 'column' })}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            aria-label="Ver autos"
          >
            {loading ? (
              <FormattedMessage
                id="SEE_TOTAL_VEHICLES"
                values={{
                  total: (
                    <CircularProgress
                      size={15}
                      sx={sx.progress}
                      thickness={5}
                    />),
                }}
              />
            ) : (
              <FormattedMessage
                id="SEE_TOTAL_VEHICLES"
                values={{
                  total: totalVehicles,
                }}
              />

            )}
          </Button>
        </Grid>

      </Grid>
    </Grid>
  )
}

export default memo(Form)
