import { titleCaps } from 'utils/universal'

export const filtersMap = {
  maker: 'marca',
  model: 'modelo',
  min: 'desde',
  max: 'hasta',
  fuelType: 'combustible',
  year: 'antiguedad',
  state: 'estado',
  city: 'ciudad',
  mileageRange: 'kilometraje',
  body: 'tipo',
  transmission: 'transmision',
  sort: 'ordenar',
  tipo_vendedor: 'tipo_vendedor',
  bonus: 'bonus',
  owner_type: 'owner_type',
  holding_type: 'holding_type',
  easy: 'easy',
  accept_financing: 'accept_financing',
  q: 'search',
  status: 'status',
  zipcode: 'zipcode',
  branch_id: 'branch_id',
  has_discount: 'descuento',
  instant_delivery: 'entrega_inmediata',
  warranty_and_satisfaction: 'garantia_triple',
  price_negotiable: 'precio_negociable',
  has_warranty: 'con_garantia',
  has_credit: 'a_credito',
  cash_only: 'de_contado',
}

export const InverseFiltersMap = Object.keys(filtersMap).reduce((acc, key) => ({
  ...acc,
  [filtersMap[key]]: key,
}), {})

export const getFilterObject = (filtersArray = []) => {
  return filtersArray
    ? filtersArray.reduce((acc, curr) => {
      const key = InverseFiltersMap[curr.substr(0, curr.indexOf('-'))]
      if (key) {
        const value = curr
          .substr(curr.indexOf('-') + 1) // Remove filter key
        acc[key] = value
      }
      return acc
    }, {})
    : {}
}

export const getFilterUrlObject = (filtersArray = []) => {
  if (!filtersArray || filtersArray.length === 0) return {}
  const vehiclefiltersMap = {
    maker: 'marca',
    model: 'modelo',
    year: 'antiguedad',
    state: 'estado',
  }
  if (filtersArray[0].includes('en-')) {
    return {
      state: filtersArray[0].substr(filtersArray[0].indexOf('-') + 1),
    }
  }
  return filtersArray ? filtersArray.reduce((pv, curr, idx) => ({
    ...pv,
    [Object.keys(vehiclefiltersMap)[idx]]: curr.substr(curr.indexOf('-') + 1),
  }
  ), {}) : {}
}

export const parseCanonical = (canonical) => {
  return canonical.includes('Suv') ? canonical.replace('Suv', 'SUV') : canonical
}

export const parseCanonialFiltersToUrl = (filtersObject) => {
  const allValuesVoid = Object.keys(filtersObject).every((item) => item === '')
  if (!filtersObject || allValuesVoid) return ''
  return Object.keys(filtersObject)
    .reduce((acc, curr) => {
      if (filtersObject[curr]) {
        const canonical = curr === 'body'
          ? `${filtersObject[curr]}`.includes('Puertas')
            ? `${filtersObject[curr].replace('Puertas', 'puertas')}`
            : `${filtersObject[curr]}`
          : `${filtersObject[curr]}`.toLowerCase()
        acc += `/${filtersMap[curr]}-${parseCanonical(canonical)}`
      }
      return acc
    }, '')
}

export const parseFiltersToUrl = (filtersObject) => {
  if (!filtersObject) return ''
  return Object.keys(filtersObject)
    .reduce((acc, curr) => {
      if (filtersObject[curr]) {
        acc += `/${filtersMap[curr]}-${filtersObject[curr]}`
      }
      return acc
    }, '')
}

export const formatFilterValue = (filter, value) => {
  const prefix = filter === 'location' ? ' en' : ' '

  if (!value) return null

  const stringValue = value.toLowerCase().split('_').map((item) => titleCaps(item)).join(', ')
  return `${prefix} ${stringValue.replace(/,([^,]*)$/, ' y $1')}`
}
