import React, { memo } from 'react'
import dynamic from 'next/dynamic'
import { useIntl } from 'react-intl'
import Page from 'components/layout/Page'
import { TESTIDS_HOME as TESTIDS } from 'testids'
import usePageProps from 'hooks/usePageProps'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Quoter from 'components/shared/Quoter'
import TimelineAndVideo from 'components/shared/TimelineAndVideo'
import MainBanner from 'components/shared/MainBanner'
import InternalSections from 'components/shared/InternalSection'
import Posts from 'components/BlogPage/Posts'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
// import Faqs from 'components/Faqs'
import sx from './styles'
import MakerAndPriceFilter from './MakerAndPriceFilter'

const ReviewsAndPressSkeleton = dynamic(() => import('components/shared/GoogleReviewsAndPress/ReviewsAndPressSkeleton'))

const GoogleReviewsAndPress = dynamic(
  () => import('components/shared/GoogleReviewsAndPress'),
  {
    loading: () => (
      <ReviewsAndPressSkeleton
        showPress
        sx={sx.googleReviewsContainer}
      />
    ),
    ssr: false,
  },
)

const VehicleTypesComponent = dynamic(
  () => import('components/shared/Swipers/VehicleTypes'),
  {
    loading: () => (
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={160}
        width="100%"
      />
    ),
    ssr: false,
  },
)

const handleScroll = () => {
  window.scrollTo({
    behavior: 'smooth',
    top: 0,
  })
}

const Home = () => {
  const { formatMessage: f } = useIntl()
  const {
    // homeFaqs,
    // homePhoneNumber,
    mainBanner,
    sycTimelineEasy,
    buyCarTimeline,
    internalSections,
    posts,
  } = usePageProps()
  const isEasyVariant = true

  return (
    <Box
      sx={sx.page}
      className="home"
    >
      <Page>
        <Box mb={1}>
          <MainBanner
            {...mainBanner}
            alignItemsStart
          >
            <MakerAndPriceFilter
              variantRow="column"
            />
            <Box mt={3}>
              <Divider />
            </Box>
            <Quoter
              variantTitle="h2"
              title="HOME_MAIN_QUOTER_TITLE"
              buttonId="vta_cotizador_main_banner_home"
              sx={sx.quoterContainer}
              buttonLabel="QUOTE_NOW_2"
            />
          </MainBanner>
        </Box>
        <Grid
          sx={sx.ratingComponent}
        >
          <Grid
            item
            xs={12}
            container
            sx={sx.container}
          >
            <Grid
              item
              xs={12}
            >
              <TimelineAndVideo
                data={buyCarTimeline}
                tabs={internalSections?.length > 0 ? internalSections[1].time_line[0].tabs : []}
              >
                <VehicleTypesComponent
                  title={f({ id: 'FIND_THE_PERFECT_VEHICLE' })}
                  variantTitle="h2"
                  testId={TESTIDS.HOME_QUICKLINKS}
                  redirectToSRP
                />
              </TimelineAndVideo>
            </Grid>

            <Grid
              item
              xs={12}
              sx={isEasyVariant ? sx.timelineContainer : null}
            >

              <TimelineAndVideo
                data={sycTimelineEasy}
                tabs={internalSections?.length > 0 ? internalSections[1].time_line[0].tabs : []}
              >
                <Quoter
                  variantTitle="h2"
                  title={isEasyVariant ? 'SYC_QUOTER_TITLE_EASY' : 'HOME_QUOTER_TITLE'}
                  buttonId="vta_cotizador_home"
                  sx={sx.quoterContainer}
                  buttonLabel={isEasyVariant ? 'SELL_NOW' : 'QUOTE_NOW'}
                />
              </TimelineAndVideo>
            </Grid>

          </Grid>
        </Grid>

        <InternalSections
          data={internalSections}
          handleScroll={handleScroll}
          testId={TESTIDS.HOME_INTERNAL_SECTIONS}
        />

        <GoogleReviewsAndPress
          variant="row"
          sx={sx.googleReviewsContainer}
          testId={TESTIDS.HOME_GOOGLE_REVIEWS}
        />
        <Posts
          testId={TESTIDS.HOME_POSTS}
          posts={posts}
          title={f({ id: 'HOME_THE_MOST_IMPORTANT_ARTICLES_FOR_YOU' })}
        />
      </Page>
    </Box>
  )
}

export default memo(Home)
