const styles = {
  root: {
    marginY: { xs: 1, lg: 3 },
    paddingY: 3,
    paddingX: { xs: 2, lg: 3 },
    backgroundColor: 'background.default',
  },
  contentCards: {
    width: { xs: '100%', md: '75%' },
  },
  contentPost: {
    width: { xs: '100%', sm: '50%' },
    pr: { xs: 1, md: 3 },
    ':nth-of-type(n+2)': {
      pt: { xs: 2, sm: 0 },
    },
    ':nth-of-type(n+3)': {
      pt: 2,
    },
  },
  contentButton: {
    width: { xs: '100%', md: '25%' },
    display: { xs: 'none', md: 'flex' },
    flexDirection: 'column',
    alignItems: 'center',
    mt: 3,
  },
  button: {
    width: 50,
    height: 50,
    backgroundColor: 'secondary.main',
    color: 'primary.contrastText',
  },
}

export default styles
