import React, { memo } from 'react'
import Box from '@mui/material/Box'
import NextImage from 'next/image'

const Image = ({
  width,
  height,
  sx: sxProp = {},
  layout = 'fill',
  objectFit = 'contain',
  className,
  ...other
}) => {
  const dimensions = { width, height, position: 'relative' }
  return (
    <>
      {layout === 'fill'
        ? (
          <Box
            sx={{ ...dimensions, ...sxProp }}
            className={className}
          >
            <NextImage
              fill
              style={{ objectFit }}
              {...other}
            />
          </Box>
        )
        : (
          <NextImage
            style={{ objectFit }}
            {...dimensions}
            {...other}
          />
        )}
    </>
  )
}

export default memo(Image)
