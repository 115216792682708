import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import reducer from 'redux/reducer' // Ajusta el path según sea necesario

// Opciones base para configurar el store
const options = {
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
}

// Configuración para middlewares en desarrollo
if (process.env.NODE_ENV !== 'production') {
  options.middleware = (getDefaultMiddleware) => getDefaultMiddleware()
}

// Función para inicializar el store
export const makeStore = (context) => {
  // Puedes acceder a `context` si es necesario (p.ej., para SSR)
  return configureStore(options)
}

// Crea un wrapper para Redux
export const wrapper = createWrapper(makeStore, {
  debug: process.env.NODE_ENV !== 'production',
  serializeState: (state) => JSON.stringify(state),
  deserializeState: (state) => JSON.parse(state),
})
