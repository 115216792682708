const styles = {
  timelineRoot: {
    padding: 0,
  },
  timelineItem: {
    ':before': {
      display: 'none',
    },
  },
  timelineSeparatorSecundary: {
    transform: 'rotate(90deg)',
    height: 40,
  },
  timelineDot: {
    margin: 0,
    backgroundColor: 'third.main',
  },
}

export default styles
